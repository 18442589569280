<template>
  <section class="land-info">
    <v-row class="row" v-if="selectedLootbox.awards.length">
      <Award
        v-for="(award, i) in selectedLootbox.awards"
        :key="i"
        :award="award"
      ></Award>
    </v-row>
    <h3 class="no-award" v-else> No award </h3>
  </section>
</template>

<script>
import Award from '../award/Award.vue'
export default {
  props: ["selectedLootbox"],
  components:{
    Award
  },
  created() {
    console.log(this.selectedLootbox);
  },
};
</script>

<style lang="scss" scoped>
.row{
  padding: 20px 0;
}
  .no-award{
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
