<template>
  <v-dialog v-model="newsDialog" width="700">
    <section class="news-dialog">
      <v-card class="card" dark>
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h3 class="white--text text-capitalize">Latest News</h3>
            <v-icon @click="newsDialog = false"
              >mdi-close-circle-outline</v-icon
            >
          </section>
        </v-card-title>

        <v-card-text class="py-3 card-text">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item, index) in newsData" :key="index">
              <h2 class="text-center mb-3 white--text" v-if="item.title">{{ item.title }}</h2>
              <h4 class="text-center mb-5 white--text" v-if="item.short_description">
                {{ item.short_description }}
              </h4>
              <div class="content-holder">
                <div class="video-container" v-if="item.video">
                  <video controls class="news-video">
                    <source :src="newsStorage(item.video)" type="video/mp4" width="100%" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div class="image-container" v-if="item.image">
                  <img
                    :src="newsStorage(item.image)"
                    :alt="item.title"
                    class="w-100"
                  />
                </div>

                <div class="body-container" v-html="item.body"></div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </v-card-text>
      </v-card>
    </section>
  </v-dialog>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import { mapActions } from "vuex";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      newsDialog: false,
      newsData: [],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoHeight: true,
        spaceBetween: 30,
      },
    };
  },
  methods: {
    ...mapActions(["getNewsInfo"]),

    newsStorage(address) {
      const { origin } = window.location;
      return `${origin}${address}`;
    },

    async get_news_data() {
      await this.getNewsInfo().then((res) => {
        if (res) {
          if (res.length) {
            this.newsData = res;

            this.newsDialog = true;
          }
        }
      });
    },

    stopVideo() {
      const videos = document.querySelectorAll(".news-video")

      videos.forEach(video => {
        video.pause()
      });
    }
  },
  mounted() {
    this.get_news_data();
  },

  watch: {
    newsDialog() {
      if (!this.newsDialog) {
        this.stopVideo()
      }
    }
  },
};
</script>

<style lang="scss">
.news-dialog {
  .card-text {
    max-height: calc(100vh - 285px);
    overflow: auto;
  }

  .content-holder {
    display: flex;
    flex-flow: column;
    gap: 0.75rem;
  }
  .video-container {
    video {
      width: 100%;
      min-width: 100%;
    }
  }

  .body-container {
    * {
      margin-bottom: 0.75rem;
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
}
</style>
