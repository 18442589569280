<template>
  <section class="land-info">
    <h2 class="item"><span class="yellow--text">Type :</span> Lootbox</h2>
    <h2 class="item">
      <span class="yellow--text">Id :</span> {{ selectedLootbox.id }}
    </h2>
    <h3 class="item">
      <span class="yellow--text">Name :</span> {{ selectedLootbox.name }}
    </h3>
    <h2 class="item">
      <span class="yellow--text">Status :</span>
      {{ selectedLootbox.status }}
    </h2>
    <h3 class="item">
      <span class="yellow--text">total_award :</span> {{ selectedLootbox.total_award }}
    </h3>
    <div class="d-flex justify-center item">
      <h2 class="mr-5">
        <span class="yellow--text">Price :</span>
        {{ selectedLootbox.sale ? selectedLootbox.sale.price.usdt.price : '---' }}$
      </h2>
      <h1 v-html="selectedLootbox.sale.priceDescription" v-if="selectedLootbox.sale"></h1>
    </div>
  </section>
</template>

<script>
export default {
  props: ["selectedLootbox"],
  created() {
    console.log(this.selectedLootbox);
  },
};
</script>

<style lang="scss" scoped>
.land-info {
  text-align: center;
  .item {
    margin-top: 2.5%;
  }
}
</style>
