import store from "../store/index.js";

export default (to, from, next) => {
  if (store.getters.token) {
    // verify with firebase or jwt
    next();
  } else {
    next("/");
  }
};
