// import Web3 from "web3";
const state = {
  metaMaskStatus: "",
};

const getters = {
  metaMaskStatus(state) {
    return state.metaMaskStatus;
  },
};

const mutations = {
  setStatus(state, payload) {
    state.metaMaskStatus = payload;
  },
};

const actions = {
  metaMaskConnect(context) {
    if (window.ethereum !== undefined) {
      return new Promise((resolve, reject) => {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            // console.log(accounts[0]);
            console.log(context);
            context.dispatch("setWalletAddress", accounts[0]);
            context.dispatch("setConnectedTo", "METAMASK");
            context.commit("setStatus", "CONNECTED");
            resolve();
          })
          .catch((error) => {
            if (error.code === 4001) {
              // EIP-1193 userRejectedRequest error
              console.log("Please connect to MetaMask.");
              context.commit("setStatus", "REJECTED");
              reject();
            }
          });
      });
    } else {
      console.log("install metamask please");
      context.commit("setStatus", "NOT_INSTALLED");
    }
  },
  autoMetamaskLogin(context) {
    if (sessionStorage.getItem("wallet_address")) {
      context.dispatch(
        "setWalletAddress",
        sessionStorage.getItem("wallet_address")
      );
    }
    if (sessionStorage.getItem("weConnectedTo")) {
      context.dispatch(
        "setConnectedTo",
        sessionStorage.getItem("weConnectedTo")
      );
    }
  },
  logoutMetaMask(context) {
    window.ethereum.on("accountsChanged", async () => {
      console.log('logout metamask');
      context.commit("setStatus", "DISCONNECTED");
      context.dispatch("setWalletAddress", null);
      context.dispatch("setConnectedTo", null);
    });
  },
  isUnlocked(context) {
    window.ethereum._metamask.isUnlocked().then((result) => {
      console.log("isUnLocked:", result);
      if (result === false && context.getters.connectedTo == "METAMASK") {
        context.dispatch("setWalletAddress", null);
        context.dispatch("setConnectedTo", null);
        context.commit("userClear",{roor:true})
      }
    });
  },
  /**
     * Switch To Polygon Network
     */
   async swithToPolygon() {

   const polygon = {
      chainId: `0x${Number(137).toString(16)}`, // A 0x-prefixed hexadecimal string
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: [
        "https://polygon-rpc.com/",
        "https://rpc-mainnet.matic.network",
        "https://matic-mainnet.chainstacklabs.com",
        "https://rpc-mainnet.maticvigil.com",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet-full-rpc.bwarelabs.com",
      ],
      blockExplorerUrls: ["https://polygonscan.com"],
    }

    // if (window.ethereum.networkVersion != 137) {
      //if we are not in polygon network
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(137).toString(16)}` }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [polygon],
            });
          } catch (err) {
            console.log("canceled:", err.message);
          }
        }
        // handle other "switch" errors
        console.log(switchError.message);
      }
    // }
  },
  // getBalance(context) {
  //   const web3 = new Web3(window.ethereum);
  //   web3.eth.getBalance(context.getters.userAccAddress, async (err, wei) => {
  //     const balance = web3.utils.fromWei(wei, "ether").substring(0, 5);
  //     context.commit("getBalance", balance);
  //   });
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
