<template>
  <section class="details">
    <v-card class="card" dark>
      <v-card-title class="card-title">
        <section class="w-100 d-flex justify-space-between align-center">
          <h3 class="white--text">{{ type }} Details</h3>
          <v-btn color="white" outlined icon small @click="close">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </section>
      </v-card-title>
      <v-card-text class="py-3 card-text">
        <div class="loading" v-if="isLoading">
          <loading-spinner></loading-spinner>
        </div>
        <section class="contant d-flex flex-column" v-else>
          <land-detail
            :selectedLand="selectedItem"
            v-if="type === 'Land'"
          ></land-detail>
          <lootbox-detail
            :selectedLootbox="selectedItem"
            v-if="type === 'Lootbox'"
          ></lootbox-detail>
        </section>
      </v-card-text>

      <v-card-actions class="card-actions">
        <v-btn
          v-if="$route.name === 'shop' && type === 'Land'"
          min-width="150"
          class="btn buy-btn mx-auto"
          :loading="isLoading"
          @click="buyLand(selectedItem)"
        >
          Buy Now
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name === 'shop' && type === 'Lootbox'"
          min-width="150"
          class="btn buy-btn mx-auto"
          :loading="isLoading"
          @click="openLootbox(selectedItem)"
        >
          Open Now
          <v-icon right size="20">mdi-lock-open-variant</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name === 'assets'"
          min-width="150"
          class="btn sell-btn mx-auto"
          @click="addToSale(selectedItem)"
        >
          Sell
          <v-icon right size="20">mdi-cart</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import LandDetail from "./content/landDetails/LandDetail.vue";
import LootboxDetail from "./content/lootboxDetails/LootboxDetail.vue";
export default {
  props: ["selectedItem", "isLoading", "type"],
  data() {
    return {};
  },

  components: { LandDetail, LootboxDetail },
  created() {},
  methods: {
    buyLand(item) {
      this.$emit("buy", item);
    },
    openLootbox(item) {
      this.$emit("openLootbox", item);
    },
    addToSale(item) {
      this.$emit("sell", item);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .card {
    border: 2px solid var(--secondary-color);
    .card-title {
      background: var(--base-color);
    }
    background: var(--background-color);
    .transfer-list {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-text {
      height: 70vh !important;
      max-height: 550px;
      overflow: auto;
      .wallet-address {
        @media only screen and(max-width: 480px) {
          max-width: 170px;
        }
      }
    }
    .card-actions {
      .btn {
        width: 97%;
        font-weight: bolder;
        transition: all 0.5s ease;
        color: var(--background-color);
        &:hover {
          letter-spacing: 5px;
        }
        &.buy-btn {
          background: var(--third-color);
        }
        &.sell-btn {
          background: rgb(255, 15, 15);
        }
      }
    }
  }
}
</style>
