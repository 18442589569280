<template>
  <div class="google-auth">
    <v-btn :loading="loading" class="google-auth-btn" type="button" id="signinBtn">
      <div style="display: flex; align-items: center">
        {{ name }}
        <i class="bx bxl-google bx-burst"></i>
      </div>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GoogleLogin",
  props: ["name"],
  data() {
    return {
      access_token: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["returnRoute"]),
  },
  mounted() {
    // const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
    const clientId =
      "187736282144-l218fe7ds1blb1dteid8sl0h4t56h88h.apps.googleusercontent.com";
    if (!window.gapi) {
      throw new Error(
        '"https://apis.google.com/js/api:client.js" needs to be included as a <script>.'
      );
    }
    window.gapi.load("auth2", () => {
      const auth2 = window.gapi.auth2.init({
        client_id: clientId,
        scope: "email profile",
        plugin_name: "streamy",
      });
      console.log(auth2.isSignedIn.wb);
      if (auth2.isSignedIn.wb) auth2.disconnect();
      const btn = document.querySelector("#signinBtn");
      btn.addEventListener("click", () => {
        this.loading = true;
        auth2
          .signIn()
          .then((googleUser) => {
            console.log(googleUser);
            this.google_login(googleUser);
            this.loading = false;
          })
          .catch((res) => {
            this.loading = false;
            this.setAlert({
              type: "error",
              message: res.error,
            });
          });
      });
      // this is a way 2
      // auth2.attachClickHandler(
      //   "signinBtn",
      //   {},
      //   (googleUser) => {
      //     console.log({ googleUser });
      //     this.google_login(googleUser);
      //     // emit("success", googleUser);
      //   },
      //   (error) => {
      //     console.log(error);
      //     // emit("failed", error);
      //     // emit("failure", error); // an alias
      //   }
      // );
    });
  },
  methods: {
    ...mapActions(["googleLogin", "setAlert"]),
    google_login(googleUser) {
      // let referral = this.$route.query.referral;

      for (const [key, value] of Object.entries(googleUser)) {
        console.log(key);
        for (const [k, v] of Object.entries(value)) {
          if (k === "access_token") {
            this.access_token = v;
            this.loading = false;
          }
        }
      }
      console.log(this.access_token);

      // if (referral) {
      //   content.affiliate_code = referral;
      // }

      this.googleLogin({ token: this.access_token }).then(() => {
        this.$router.push({ name: "Home" });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.google-auth {
  .google-auth-btn {
    width: 100%;
    border-radius: 5px;
    padding: 8px 0;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border: 2px solid var(--light-blue);
    background: transparent;
    color: var(--color7);
    transition: all 0.4s ease;
    color: var(--light-blue);
    i {
      font-size: 22px;
      margin-left: 10px;
      // @include sm {
      //   font-size: 14px;
      // }
    }
    &:hover {
      background: rgba(110, 202, 255, 0.105);
      border-color: transparent;
    }
    // @include sm {
    //   font-size: 12px;
    // }
  }
}
</style>
