<template>
  <section class="contact" id="contact">
    <h1 class="wrapper-head text-center">Contact Us</h1>

    <section class="justify-center">
      
          <v-form
            @submit.prevent="validate"
            ref="contact_us"
            v-model="valid"
            lazy-validation
            class="form mx-auto"
          >
            <div class="d-flex my-4 align-center">
              <v-icon class="info-icon">mdi-information-variant</v-icon>
              <p class="grey--text info-text text-subtitle-2" >
                Fill up the form and our Team will get back to you within 24
                hours.
              </p>
              <v-spacer></v-spacer>
              <v-img
                src="@/assets/images/contact-us.png"
                contain
                max-width="8%"
                class="contact-icon"
              ></v-img>
            </div>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  type="email"
                  :rules="rules.email_rules"
                  v-model="form.email"
                  dark
                  filled
                  dense
                  append-icon="mdi-email"
                  messages="Your email tell us who you are so that we can answer your. "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Message"
                  :rules="rules.message_rules"
                  v-model="form.body"
                  filled
                  dense
                  dark
                  append-icon="mdi-message"
                  rows="5"
                  messages="The message can be a Criticism, idea or a question that helps us to improve the site."
                ></v-textarea>
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              class="submit-btn mt-5"
              width="100%"
              outlined
              :loading="loading"
              :disabled="loading"
            >
              Submit
            </v-btn>
          </v-form>
       
    </section>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4 "
      elevation="100"
      absolute
      centerd
      shaped
      top
      transition="slide-y-transition"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: true,
      loading: false,
      message: "",
      snackbar: false,
      form: {
        email: "",
        body: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  methods: {
    ...mapActions(["sendContactForm"]),

    initData() {
      this.form = {
        email: "",
        body: "",
      };

      this.$refs.contact_us.reset();
    },

    validate() {
      this.$refs.contact_us.validate();

      this.$nextTick(async () => {
        if (this.valid) {
          this.loading = true;

          //
          await this.sendContactForm(this.form).then((res) => {
            this.snackbar = res;
            this.message = "your message send successfully";
            if (res) {
              this.initData();
            }
          });

          //
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
  background: radial-gradient(ellipse at bottom right, #00a2ff76 5%, black 70%);
  position: relative;
  height: 100%;
  .form {
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.052);
    border-radius: 10px;
    width: 95%;
    max-width: 600px;
    .info-icon{
      background: rgb(230, 230, 230);
      color: black;
      width: 5%;
      height: 50px;
      border-radius: 50px;
      margin-right: 10px;
    }
    .info-text{
      width: 60%;
    }
    .submit-btn{
        color: var(--secondary-color);
      }
    @media only screen and(max-width: 450px) {
      .info-icon{
        width: 15%;
      }
      .info-text{
        width: 90%;
      }
      .contact-icon{
        display: none;
      }
      
    }
  }
}
</style>
