<template>
  <div class="main-social-icons d-flex py-5 py-sm-10">
    <a
      href="https://www.instagram.com/ecosmartecs/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon>mdi-instagram</v-icon>
    </a>
    <a
      href="https://t.me/EcoSmartECS"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon>mdi-send</v-icon>
    </a>
    <a
      href="https://twitter.com/EcoSmartECS"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon>mdi-twitter</v-icon>
    </a>
    <a
      href="https://www.youtube.com/channel/UCZyavwOXisC7aVVYX8FbnPQ/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon>mdi-youtube</v-icon>
    </a>
    <a
      href="https://discord.gg/WJKCk6c"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon>mdi-robot</v-icon>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.main-social-icons {
  justify-content: center;
  // background: radial-gradient(ellipse at top right, #00a2ff76 5%, black 70%);
  background: linear-gradient(to bottom , #00a2ff76 , #00d5ffa3 , #00a2ff76 );
  a {
    padding: 15px;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
    margin-right: 20px;
    transition: all .5s linear;
    &:last-child {
      margin-right: 0;
      i{
        margin-top: -3px;
      }
    }
    i {
      color: black !important;
      font-size: 35px !important;
      @media only screen and(max-width: 450px) {
        font-size: 25px !important;
      
    }
    }
    &:hover{
      background: black;
      i{
        color: white !important;
        transform: scale(1.1);
      }
    }
    @media only screen and(max-width: 450px) {
      padding: 8px;
      margin-right: 10px;
    }
  }
}
</style>
