<template>
  <v-col class="col" cols="6" md="6">
    <div class="card">
      <img
        :src="award.item.image"
        alt=""
        width="100%"
        v-if="award.item.image"
      />
      <h1 class="no-image" v-else>No image</h1>
      <section class="details">
      <h1>{{ award.item.name }}</h1>
      <h6>Chance : {{ award.chances }}</h6>
      <h6>amount : {{ award.amount }}</h6>
      </section>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>More informations</v-expansion-panel-header>
          <v-expansion-panel-content>
            <AwardMoreInfo :awardInformations="award.item"></AwardMoreInfo>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-col>
</template>

<script>
import AwardMoreInfo from "./AwardMoreInfo.vue";
export default {
  props: ["award"],
  components: {
    AwardMoreInfo,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.col {
  .card {
    border: 1px solid rgb(0, 247, 255);
    .v-expansion-panels {
      .v-expansion-panel-header {
        user-select: none;
        min-height: 30px;
        height: 30px;
        font-size: 11px;
        padding: 0 8px;
      }
    }
    .no-image{
      padding: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .details{
      padding: 5px;
    }
  }
}
</style>
