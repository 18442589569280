import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import GiftOpening from "../views/giftOpening/GiftOpening.vue";
import authCheck from "../utils/authCheck.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "about" */ "../views/authentication/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "about" */ "../views/authentication/Register.vue"),
  },
  {
    path: "/gift-opening/:id",
    name: "gift-opening",
    component: GiftOpening,
  },
  {
    path: "/shop",
    name: "shop",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/shop"),
  },
  {
    path: "/map",
    name: "map",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "map" */
        "../views/map"
      ),
  },
  // {
  //     path: "/contact",
  //     name: "contact",
  //     component: () =>
  //         import ( /* webpackChunkName: "contact-us" */ "../views/Contact.vue"),
  // },
  {
    path: "/payment",
    name: "payment",
    beforeEnter: authCheck,
    component: () =>
      import(/* webpackChunkName: "Payment" */ "../views/payment"),
  },
  {
    path: "/payments/redirect",
    name: "paymentCallback",
    component: () =>
      import(
        /* webpackChunkName: "Payment" */
        "../views/payment/PaymentCallback.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    beforeEnter: authCheck,
    component: () =>
      import(/* webpackChunkName: "Payment" */ "../views/profile/Profile.vue"),
  },
  {
    path: "/verify-email",
    name: "verify-email",

    component: () =>
      import(
        /* webpackChunkName: "Payment" */
        "../views/profile/EamilVerifyCallback.vue"
      ),
  },
  {
    path: "/assets",
    name: "assets",
    beforeEnter: authCheck,
    component: () => import("../views/assets/Assets.vue"),
  },
  {
    path: "/thirdparty-login",
    name: "thirdparty-login",
    component: () => import("../views/thirdparty/ThirdpartyLogin.vue"),
  },
  {
    path: "/privacyPolicy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/termsAndConditions",
    name: "terms-and-conditions",
    component: () => import("../views/TermsAndConditions.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
