<template>
  <section class="land-details">
    <div class="land-details-head">
      <v-img
        :src="selectedLootbox.image"
        width="100%"
        max-width="394"
        min-height="225px"
        contain
        class="land-img mx-auto"
        @load="imgLoaded"
      >
        <v-skeleton-loader
          type="image@2"
          class="land-img-skeleton"
          v-if="!imgIsLoaded"
          width="100%"
          height="225"
        ></v-skeleton-loader>
      </v-img>
      <h3 class="land-name">
        {{ selectedLootbox.name ? selectedLootbox.name : "No Name" }}
      </h3>
    </div>
    <main class="land-details-content">
      <div class="base-tabs">
        <ul class="d-flex">
          <li
            v-for="(item, i) in baseTabs"
            :key="i"
            @click="changeTabs(item)"
            class="white--text"
            :class="item.isActive ? 'active' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="line"></div>
      <Info v-if="baseTabs[0].isActive" :selectedLootbox="selectedLootbox" />
      <Awards v-if="baseTabs[1].isActive" :selectedLootbox="selectedLootbox" />
      
    </main>
  </section>
</template>

<script>
import Info from "./tabs/Info.vue";
import Awards from "./tabs/award/Awards.vue";
export default {
  props: ["selectedLootbox"],
  data() {
    return {
      baseTabs: [
        {
          name: "Info",
          isActive: true,
        },
        {
          name: "Awards",
          isActive: false,
        },
        
      ],
      imgIsLoaded: false,
      mainApi: process.env.VUE_APP_ROOT_API,
    };
  },
  components: {
    Info,
    Awards
  },

  methods: {
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.baseTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.baseTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
    imgLoaded() {
      this.imgIsLoaded = true;
      console.log(this.imgIsLoaded);
    },
  },
};
</script>

<style lang="scss" scoped>
.land-details {
  &-head {
    .land-img {
      border-radius: 10px;
    }
    .land-name {
      color: white;
      margin: 10px;
      text-align: center;
    }
  }
  &-content {
    .base-tabs {
      ul {
        li {
          margin: 0 5px;
          font-size: 16px;

          @media only screen and(max-width: 650px) {
            margin: 0;
            font-size: 12px;
            padding: 5px 4px;
          }
          @media only screen and(max-width: 350px) {
            margin: 0;
            font-size: 8px;
            padding: 0 4px;
          }
        }
      }
    }
  }
}
</style>
