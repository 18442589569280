import mainApi from "../../plugins/axios/axios.js";
const state = {
  token: null,
  user: null,
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },

  setToken(state, payload) {
    state.token = payload;
    localStorage.setItem("token", payload);
  },
};

const actions = {
  /**
   * User Exist
   * @param {wallet address} payload
   */
  // async userExist(_, payload) {
  //   try {
  //     const { data } = await mainApi.post("users/auth/check_user", payload);

  //     //
  //     let { userExists, twoFactorAuthentication } = data.result;

  //     //
  //     return { userExists, twoFactorAuthentication };
  //   } catch (error) {
  //     return false;
  //   }
  // },

  /**
   * Register
   */
  async register({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/register", payload);

      //
      let token = data.data.token;
      let user = data.data;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },

  /**
   * Login
   */
  async login({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/login", payload);

      //
      let token = data.result.token;
      let user = data.result;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  autoLogin({ commit }) {
    if (localStorage.getItem("token")) {
      commit("setToken", localStorage.getItem("token"));
    }
  },
  logout({ commit }) {
    commit("setToken", null);
    localStorage.clear();
  },

  setThirdpartyToken({ commit }, token) {
    commit("setToken", token);
  },
  setThirdpartyUser({ commit }, user) {
    commit("setUser", user);
  },
  googleLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("users/auth/googleLogin", payload)
        .then((result) => {
          console.log(result);
          let token = result.data.result.token;
          // let user = result.data.result;

          // commit("setUser", user);
          commit("setToken", token);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
