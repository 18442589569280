<template>
  <div class="award-more-info">
    <v-row no-gutters v-if="awardInformations.awards">
      <v-col
        class="col"
        v-for="(awardInfo, i) in awardInformations.awards"
        :key="i"
        cols="6"
      >
        <div class="card">
          <img :src="awardInfo.item.image" width="100%" />
          <div>{{ awardInfo.item.name }}</div>
          <div>chance: {{ awardInfo.chances }}</div>
        </div>
      </v-col>
    </v-row>
    <ul v-else>
      <li v-for="(value,name, i) in formatedAwardInfo" :key="i">
        <div>{{ name }} : {{ value ? value : '---' }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["awardInformations"],
  computed:{
    formatedAwardInfo(){
      
        const itemSelectedCopy = Object.assign({}, this.awardInformations); //copy from itemSelected
        for (const key in itemSelectedCopy) {
          if(!itemSelectedCopy[key] || key === 'image' || key === 'animate'){
            delete itemSelectedCopy[key]
          }
        }
        return itemSelectedCopy;
    }
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
.col {
  .card {
    border: 1px solid cyan;
    margin: 0 1px;
    font-size: 9px;
  }
}
ul {
  li {
    font-size: 10px;
  }
}
</style>
