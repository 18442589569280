<template>
  <section class="home">
    <FirstSection />
    <video-player></video-player>
    <Socials />
    <LandsOfAzolite></LandsOfAzolite>
    <game-mode></game-mode>
    <SalesLands />
    <Contact></Contact>
    <Partnerships />
  </section>
</template>

<script>
import FirstSection from "../components/home/FirstSection.vue";
import Socials from "../components/home/Socials.vue";
import LandsOfAzolite from "../components/home/LandsOfAzolite.vue";
import GameMode from "../components/home/GameMode.vue";
import Contact from "../views/Contact.vue";
import VideoPlayer from "../components/home/videoPlayer/VideoPlayer.vue";
import Partnerships from "../components/home/Partnerships.vue";
import SalesLands from "../components/home/SalesLands.vue";

export default {
  name: "Home",

  components: {
    FirstSection,
    LandsOfAzolite,
    Socials,
    GameMode,
    Contact,
    VideoPlayer,
    Partnerships,
    SalesLands,
  },
};
</script>
<style lang="scss" scoped></style>
